import PropTypes from 'prop-types'
import { minorErrorCodes, reconCodes } from '../error-codes'

const MinorCodeFilter = ({ filter, setFilter }) => {
  const changed = (e) => {
    const newStatus = e.target.value
    setFilter({ ...filter, error_code_minor: newStatus })
  }
  const errorCodes = Array.from(minorErrorCodes.keys()).sort((a, b) => a - b) // Numeric sort
  const recon = Array.from(reconCodes.keys()).sort((a, b) => a - b)

  const getMinorCodeDetails = (failure_code) => {
    const minorCode = minorErrorCodes.get(failure_code)
    const detail = minorCode ? minorCode.get(failure_code) : null
    return detail
  }

  const getReconCodeDetails = (recon_code) => {
    const rc = reconCodes.get(recon_code)
    const detail = rc ? rc.get(recon_code) : null
    return detail
  }

  return (
    <div className='filter status'>
      <select value={filter.error_code_minor || ''} onChange={changed}>
        <optgroup label='Minor Codes'>
          {errorCodes.map((code) => (
            <option key={code} value={code}>
              {`failed (${code} - ${getMinorCodeDetails(code) || '(no description)'})`}
            </option>
          ))}          
        </optgroup>
        <optgroup label='Recon Statuses'>
          {recon.map((code) => (
            <option key={code} value={`recon_${code}`} className='failed'>
              {`recon (${getReconCodeDetails(code) || '(no description)'})`}
            </option>
          ))}
        </optgroup>
      </select>
    </div>
  )
}

MinorCodeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default MinorCodeFilter
