import { ResponsiveContainer, Treemap, Tooltip } from 'recharts'
import LoadingSpinner from '../../loading-spinner/loading-spinner'
import { useTheme } from '../../theme'
import PropTypes from 'prop-types'

const TreemapTooltipContent = (o) => {
  if (
    o &&
    o.payload &&
    Object.hasOwn(o.payload, 'length') &&
    o.payload.length > 0 &&
    Object.hasOwn(o.payload[0], 'payload')
  ) {
    // Yes, the tooltip on Treemap is fundamentally broken.
    const { name, label, parent, size } = o.payload[0].payload
    if (name && size) {
      return (
        <div className='customized-tooltip-content'>
          <p>{label ?? name}</p>
          {parent && (
            <p>
              <em>{parent}</em>
            </p>
          )}
          <p>Count: {size}</p>
        </div>
      )
    }
  }

  return <div></div>
}

const TreemapChart = ({ data, failed, loading, title }) => {
  //console.log(data)
  const theme = useTheme()
  const isMobile = window.matchMedia('(max-width: 420px)').matches

  if (failed) {
    return (
      <div className='loading-spinner-wrapper'>
        <div>
          <LoadingSpinner color='red' />
          <div>could not retrieve data</div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className='loading-spinner-wrapper'>
        <LoadingSpinner />
      </div>
    )
  }

  if (!data?.length) {
    return (
      <div className='stacked-bar-chart-wrapper'>
        <span className='table-title'>{title}</span>
        <div className='no-data-dashboard'>
          <h1>NO DATA</h1>
        </div>
      </div>
    )
  }

  return (
    <div className='stacked-bar-chart-wrapper'>
      <div className='graph-title-container'>
        <span className='table-title'>{title}</span>
      </div>
      <ResponsiveContainer>
        <Treemap data={data} dataKey='size' stroke='#fff' fill={theme?.graphColours?.failed}>
          <Tooltip content={<TreemapTooltipContent />} />
        </Treemap>
      </ResponsiveContainer>
    </div>
  )
}

TreemapChart.propTypes = {
  data: PropTypes.array,
  failed: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
}

export default TreemapChart
