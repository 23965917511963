import { PropsWithChildren, useContext } from 'react'
import PropTypes, { bool } from 'prop-types'
import { createContext, useState, useEffect } from 'react'
import { useInterval } from './timing/use-interval'
import { useAuth } from './auth'
import { getMyOrgScopeTree } from './api'

export const TYPE_ROOT = 'root'
export const TYPE_CONTRACT = 'contract'
export const TYPE_MERCHANT = 'merchant'
export const TYPE_ORGANISATION = 'organisation'

// How often do we poll for changes to the user's org scope tree,
// until we replace polling with a web socket.
const LIVE_UPDATE_INTERVAL = 60000 // ms

export interface OrgScopeContext {
  loading: boolean
  failed: any
  tree: OrgTreeEntry[]
  byID: OrgTreeByID
}

export interface OrgTreeEntry {
  name: string
  id: string
  type: string
  children?: OrgTreeEntry[]

  parentID?: string // populated on org load API call
}

interface OrgScopeProviderState {
  data?: OrgTreeEntry[]
  loading: boolean
  failed: any
}

export interface OrgTreeByID {
  [key: string]: OrgTreeEntry
}

export interface OrgsByType {
  [key: string]: OrgTreeEntry[]
}

/** A React Hook that returns the org scope visible to the current
 * user. Structure is:
 * {
 *  loading: boolean, // true while busy loading
 *  failed: string,   // non-null if fetching failed
 *  tree: { id, name, type, children: []  } // Root of the org tree visible to user (nested children)
 *  byID: {} // id -> org lookup map, which also contains nested `children` (all flattened)
 *  branch: [{id, name, type}] // Optional: A 'path' top-down of the org tree, from the highest root visible
 *                             // to the user, to the org with the ID passed into useOrgScope(orgID)
 * }
 */
export const useOrgScope = (orgID?: string) => {
  const ctx = useContext(OrgScope)
  // If an orgID was passed in, also include a `branch` field that is a top-down
  // walk to the given org, if it is in scope.
  // This is a list of: [{id, type, name}] which only includes the
  // orgs that the current user has visibility of.
  const branch =
    orgID && ctx?.byID
      ? getBranchTo(ctx.byID, [ctx.byID[orgID]]).map((o) =>
          o
            ? {
                id: o.id,
                type: o.type,
                name: o.name,
              }
            : o
        )
      : []
  return { ...ctx, branch }
}
/** OrgScopeRequired is a handy utility to wrap any component that should only be rendered
 * if the user's org scope is known, i.e. has it least loaded once successfully. Saves
 * implementors from having to perform the same checks over and over to protect their
 * component logic from failures e.g. DURING the loading of org scope.
 */
export const OrgScopeRequired = ({ children }: PropsWithChildren) => {
  const { loading, tree, failed } = useOrgScope()
  // Busy loading (first time, no tree available already)
  if (loading && !tree) {
    return <div className='loading'>Loading...</div>
  }
  // Failed to load, no org tree available
  if (failed && !tree) {
    return (
      <div className='error'>
        Something went wrong while determining your user&apos;s access scope, please try again.
      </div>
    )
  }
  return children
}
OrgScopeRequired.propTypes = {
  children: PropTypes.any.isRequired,
}

/* A React Context that makes available the org scope tree that the current
 * user has access to. This provides the following:
 * {
 *  loading: boolean, // true while busy loading
 *  failed: string,   // non-null if fetching failed
 *  tree: { id, name, type, children: []  } // Root of the org tree visible to user (nested children)
 *  byID: {} // id -> org lookup map, which also contains nested `children`
 * }
 **/
const OrgScope = createContext<OrgScopeContext>({
  loading: false,
  failed: null,
  tree: [],
  byID: {},
})
OrgScope.displayName = 'OrgScope'

/** foldOrgByID produces a 'flat' ID -> Org lookup (as object),
 * as a fold over an org tree. */
const foldOrgByID = (byID: OrgTreeByID = {}, org: OrgTreeEntry) => {
  if (org) {
    // TODO: There is currently a bug in the service endpoint that often returns
    // duplicates of the same merchant, but sometimes without name. As a temporary
    // solution, we merge instead of replace.
    byID[org.id] = org

    if (org.children) {
      org.children
        // Recurse (add all children to "by id" map)
        .reduce(foldOrgByID, byID)
    }
  }
  return byID
}

/** getBranchTo is a utility to produce a top-down walk down the org
 * tree to the given org. It is implemented as a recursive fold*/
export const getBranchTo = (byID: OrgTreeByID = {}, walk: OrgTreeEntry[] = []): OrgTreeEntry[] =>
  walk[0] && walk[0].parentID ? getBranchTo(byID, [byID[walk[0].parentID], ...walk]) : walk

/** Given an org, and a user's "root" org, returns the text of that
 * organization's name. Takes care of some subtleties / sensitivities,
 * such as not alerting top-level contract users to the possibility of
 * other contracts in the system, etc. */
export const orgName = (org: OrgTreeEntry, userRoot: OrgTreeEntry) =>
  org.type === TYPE_ROOT
    ? 'Global'
    : org.id === userRoot.id && userRoot.type === TYPE_CONTRACT
    ? 'All'
    : org?.name || org?.id || '?'

export const getOrgByType = (byID: OrgTreeByID) => {
  const typed: OrgsByType = {}
  for (let id in byID) {
    let org = byID[id]
    if (!Object.hasOwn(typed, org.type)) {
      typed[org.type] = []
    }
    typed[org.type].push(org)
  }

  for (let type in typed) {
    typed[type].sort((x, y) => x.name.localeCompare(y.name))
  }

  return typed
}

const populateParentID = (org: OrgTreeEntry, parentID?: string) => {
  org.parentID = parentID
  if (org.children) {
    org.children.forEach((child) => populateParentID(child, org.id))
  }
}

/** OrgScopeProvider fetches and manages the scope of organizations
 * that the current user has access to. Child components will want
 * to make use of `useOrgScope` to obtain the data - both in tree,
 * and map, form. */
export const OrgScopeProvider = ({ children }: PropsWithChildren) => {
  // If user is authenticated, pull org scope tree
  // for this user, and stick it in context
  const { token, expired } = useAuth()
  const [scope, setScope] = useState<OrgScopeProviderState>({
    data: undefined,
    loading: false,
    failed: null,
  })
  const fetchMyScopeTree = async () => {
    // Only if authenticated
    if (!token || expired) {
      return
    }
    setScope({ ...scope, loading: true, failed: null })
    try {
      const data = (await getMyOrgScopeTree(token)) as OrgTreeEntry[]
      data?.forEach((org) => populateParentID(org, undefined))

      setScope({
        ...scope,
        loading: false,
        failed: null,
        data: data,
      })
    } catch (failed) {
      setScope({ ...scope, loading: false, failed })
    }
  }

  // Whenever the user token changes, e.g. such as at the point of
  // logging in, fetch immediately
  useEffect(() => {
    fetchMyScopeTree()
  }, [token, expired])

  // Thereafter, fetch periodically (no web socket to watch this yet)
  useInterval(fetchMyScopeTree, LIVE_UPDATE_INTERVAL, false)

  const value = {
    loading: scope.loading,
    failed: scope.failed,
    tree: scope.data ?? [],
    byID: scope.data?.reduce(foldOrgByID, {}) ?? {}, // Map: { id -> org }
  }

  return <OrgScope.Provider value={value}>{children}</OrgScope.Provider>
}
OrgScopeProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
