// map of failureCode to description
export const majorErrorCodes = new Map()
majorErrorCodes.set(0, 'General failure') // these should be extremely rare
majorErrorCodes.set(1, 'Invalid card information')
majorErrorCodes.set(2, 'Stolen card')
majorErrorCodes.set(3, 'Expired card')
majorErrorCodes.set(4, 'Declined by upstream (post-verification)')
majorErrorCodes.set(5, 'Invalid order amount')
majorErrorCodes.set(6, 'Scoring failed')
majorErrorCodes.set(7, 'Currency not supported')
majorErrorCodes.set(8, 'Fraudulent transaction')
majorErrorCodes.set(9, 'Declined by upstream (pre-verification)')
majorErrorCodes.set(10, 'Merchant not active')
majorErrorCodes.set(11, 'Invalid customer destination URL')
majorErrorCodes.set(13, 'Invalid callback URL')
majorErrorCodes.set(14, 'Un-routable transaction / no upstream bank willing to accept')
majorErrorCodes.set(15, 'Invalid card holder details	')
majorErrorCodes.set(16, 'Currency not configured')
majorErrorCodes.set(17, 'Already processed')
majorErrorCodes.set(18, 'System error (upstream bank)')
//majorErrorCodes.set(22, 'Abandoned') // Abandoned not used as major code since we alias it as a full status

const declineMinorCodes = new Map()
declineMinorCodes.set(1, 'Decline 3DS Check failed')
declineMinorCodes.set(2, 'Invalid CVV')
declineMinorCodes.set(3, 'Communication error')
declineMinorCodes.set(4, 'Exceeds frequency limit')
declineMinorCodes.set(5, 'Exceeds acquirer limit')
declineMinorCodes.set(6, 'Unsupported brand')
declineMinorCodes.set(7, 'Limit exceeded')
declineMinorCodes.set(8, 'Suspected fraud')
declineMinorCodes.set(9, 'Do not honor')
declineMinorCodes.set(11, 'Declined by External scoring')
declineMinorCodes.set(12, 'Blocked BIN')
declineMinorCodes.set(13, 'Invalid PAN')
declineMinorCodes.set(41, 'Test card')
declineMinorCodes.set(42, 'Transaction deleted or refunded')
declineMinorCodes.set(43, 'Data Issue')
declineMinorCodes.set(44, 'Authorization Required')
declineMinorCodes.set(45, 'Authorization Failure')
declineMinorCodes.set(46, 'Invalid Transaction')
declineMinorCodes.set(47, 'Transaction cancelled')
declineMinorCodes.set(48, 'Bad payload')
declineMinorCodes.set(100, 'Decline (general, no comments)')
declineMinorCodes.set(101, 'Decline, expired card')
declineMinorCodes.set(102, 'Decline, suspected fraud')
declineMinorCodes.set(103, 'Decline, card acceptor contact acquirer')
declineMinorCodes.set(104, 'Decline, restricted card')
declineMinorCodes.set(105, 'Decline, card acceptor call acquirers security department')
declineMinorCodes.set(106, 'Decline, allowable PIN tries exceeded')
declineMinorCodes.set(107, 'Decline, refer to card issuer')
declineMinorCodes.set(108, 'Decline, refer to card issuers special conditions')
declineMinorCodes.set(109, 'Decline, invalid merchant')
declineMinorCodes.set(110, 'Decline, invalid amount')
declineMinorCodes.set(111, 'Decline, invalid card number')
declineMinorCodes.set(112, 'Decline, PIN data required')
declineMinorCodes.set(113, 'Decline, unacceptable fee')
declineMinorCodes.set(114, 'Decline, no account of type requested')
declineMinorCodes.set(115, 'Decline, requested function not supported')
declineMinorCodes.set(116, 'Decline, not sufficient funds')
declineMinorCodes.set(117, 'Decline, incorrect PIN')
declineMinorCodes.set(118, 'Decline, no card record')
declineMinorCodes.set(119, 'Decline, transaction not permitted to cardholder')
declineMinorCodes.set(120, 'Decline, transaction not permitted to terminal')
declineMinorCodes.set(121, 'Decline, exceeds withdrawal amount limit')
declineMinorCodes.set(122, 'Decline, security violation')
declineMinorCodes.set(123, 'Decline, exceeds withdrawal frequency limit')
declineMinorCodes.set(124, 'Decline, violation of law')
declineMinorCodes.set(125, 'Decline, card not effective')
declineMinorCodes.set(126, 'Decline, invalid PIN block')
declineMinorCodes.set(127, 'Decline, PIN length error')
declineMinorCodes.set(128, 'Decline, PIN kay synch error')
declineMinorCodes.set(129, 'Decline, suspected counterfeit card')
declineMinorCodes.set(134, 'Decline, invalid transaction')
declineMinorCodes.set(135, 'Decline, re-enter transaction')
declineMinorCodes.set(139, 'Decline, card issuer or switch inoperative')
declineMinorCodes.set(141, 'Decline, system malfunction')
declineMinorCodes.set(142, 'Unknown payment route')
declineMinorCodes.set(180, 'Decline, by cardholders wish')
declineMinorCodes.set(181, 'Decline, Card is not active')
declineMinorCodes.set(182, 'Decline, Blocked card')
declineMinorCodes.set(183, 'Decline, Card is not active')
declineMinorCodes.set(184, 'Decline, Card is not active')
declineMinorCodes.set(185, 'Decline, Card is not active')
declineMinorCodes.set(186, 'Decline, Card is not active')
declineMinorCodes.set(187, 'Decline, Card is not active')
declineMinorCodes.set(188, 'Decline, Card is not active')
declineMinorCodes.set(189, 'Decline, Card is not active')
declineMinorCodes.set(190, 'Decline, Card is not active')
declineMinorCodes.set(192, 'Decline, Card is not active')
declineMinorCodes.set(193, 'Decline, Card is not active')
declineMinorCodes.set(194, 'Decline, Card is not active')
declineMinorCodes.set(195, 'Decline, Card is not active')
declineMinorCodes.set(196, 'Decline, Card is not active')
declineMinorCodes.set(197, 'Decline, Card is not active')
declineMinorCodes.set(198, 'Decline, Card is not active')
declineMinorCodes.set(199, 'Decline, Card is not active')
declineMinorCodes.set(200, 'Pick-up (general, no comments)')
declineMinorCodes.set(201, 'Pick-up, expired card')
declineMinorCodes.set(202, 'Pick-up, suspected fraud')
declineMinorCodes.set(203, 'Pick-up, card acceptor contact card acquirer')
declineMinorCodes.set(204, 'Pick-up, restricted card')
declineMinorCodes.set(205, 'Pick-up, card acceptor call acquirers security department')
declineMinorCodes.set(206, 'Pick-up, allowable PIN tries exceeded')
declineMinorCodes.set(207, 'Pick-up, special conditions')
declineMinorCodes.set(208, 'Pick-up, lost card')
declineMinorCodes.set(209, 'Pick-up, stolen card')
declineMinorCodes.set(210, 'Pick-up, suspected counterfeit card')
declineMinorCodes.set(221, 'Abandoned, fingerprinting')
declineMinorCodes.set(222, 'Abandoned, KYC')
declineMinorCodes.set(223, 'Abandoned, 3DS')
declineMinorCodes.set(224, 'Abandoned, settlement')
declineMinorCodes.set(300, 'Status message: file action successful')
declineMinorCodes.set(301, 'Status message: file action not supported by receiver')
declineMinorCodes.set(302, 'Status message: unable to locate record on file')
declineMinorCodes.set(303, 'Status message: duplicate record, old record replaced')
declineMinorCodes.set(304, 'Status message: file record field edit error')
declineMinorCodes.set(305, 'Status message: file locked out')
declineMinorCodes.set(306, 'Status message: file action not successful')
declineMinorCodes.set(307, 'Status message: file data format error')
declineMinorCodes.set(308, 'Status message: duplicate record, new record rejected')
declineMinorCodes.set(309, 'Status message: unknown file')
declineMinorCodes.set(500, 'Status message: reconciled, in balance')
declineMinorCodes.set(501, 'Status message: reconciled, out of balance')
declineMinorCodes.set(502, 'Status message: amount not reconciled, totals provided')
declineMinorCodes.set(503, 'Status message: totals for reconciliation not available')
declineMinorCodes.set(504, 'Status message: not reconciled, totals provided')
declineMinorCodes.set(600, 'Accepted (for administrative info)')
declineMinorCodes.set(601, 'Status message: impossible to trace back original transaction')
declineMinorCodes.set(602, 'Status message: invalid transaction reference number')
declineMinorCodes.set(603, 'Status message: reference number/PAN incompatible')
declineMinorCodes.set(604, 'Status message: POS photograph is not available')
declineMinorCodes.set(605, 'Status message: requested item supplied')
declineMinorCodes.set(
  606,
  'Status message: request cannot be fulfilled - required documentation is not available'
)
declineMinorCodes.set(680, 'List ready')
declineMinorCodes.set(681, 'List not ready')
declineMinorCodes.set(700, 'Accepted (for fee collection)')
declineMinorCodes.set(800, 'Accepted (for network management)')
declineMinorCodes.set(900, 'Advice acknowledged, no financial liability accepted')
declineMinorCodes.set(901, 'Advice acknowledged, financial liability accepted')
declineMinorCodes.set(902, 'Decline reason message: invalid transaction')
declineMinorCodes.set(903, 'Status message: re-enter transaction')
declineMinorCodes.set(904, 'Decline reason message: format error')
declineMinorCodes.set(905, 'Decline reason message: acquirer not supported by switch')
declineMinorCodes.set(906, 'Decline reason message: cutover in process')
declineMinorCodes.set(907, 'Decline reason message: card issuer or switch inoperative')
declineMinorCodes.set(
  908,
  'Decline reason message: transaction destination cannot be found for routing'
)
declineMinorCodes.set(909, 'Decline reason message: system malfunction')
declineMinorCodes.set(910, 'Decline reason message: card issuer signed off')
declineMinorCodes.set(911, 'Decline reason message: card issuer timed out')
declineMinorCodes.set(912, 'Decline reason message: card issuer unavailable')
declineMinorCodes.set(913, 'Decline reason message: duplicate transmission')
declineMinorCodes.set(914, 'Decline reason message: not able to trace back to original transaction')
declineMinorCodes.set(915, 'Decline reason message: reconciliation cutover or checkpoint error')
declineMinorCodes.set(916, 'Decline reason message: MAC incorrect')
declineMinorCodes.set(917, 'Decline reason message: MAC key sync error')
declineMinorCodes.set(918, 'Decline reason message: no communication keys available for use')
declineMinorCodes.set(919, 'Decline reason message: encryption key sync error')
declineMinorCodes.set(920, 'Decline reason message: security software/hardware error - try again')
declineMinorCodes.set(921, 'Decline reason message: security software/hardware error - no action')
declineMinorCodes.set(922, 'Decline reason message: message number out of sequence')
declineMinorCodes.set(923, 'Status message: request in progress')
declineMinorCodes.set(950, 'Decline reason message: violation of business arrangement')

// minor error codes is a map of failureCode to a map of minor code to a error description
export const minorErrorCodes = new Map()
minorErrorCodes.set(1, declineMinorCodes)
minorErrorCodes.set(2, declineMinorCodes)
minorErrorCodes.set(3, declineMinorCodes)
minorErrorCodes.set(4, declineMinorCodes) // Decline, post-verification
minorErrorCodes.set(5, declineMinorCodes)
minorErrorCodes.set(6, declineMinorCodes)
minorErrorCodes.set(7, declineMinorCodes)
minorErrorCodes.set(8, declineMinorCodes)
minorErrorCodes.set(9, declineMinorCodes) // Decline, pre-verification
minorErrorCodes.set(11, declineMinorCodes)
minorErrorCodes.set(12, declineMinorCodes)
minorErrorCodes.set(14, declineMinorCodes) // Decline, routing
minorErrorCodes.set(22, declineMinorCodes) // Decline, abandoned
minorErrorCodes.set(41, declineMinorCodes)
minorErrorCodes.set(42, declineMinorCodes)
minorErrorCodes.set(43, declineMinorCodes)
minorErrorCodes.set(44, declineMinorCodes)
minorErrorCodes.set(100, declineMinorCodes)
minorErrorCodes.set(101, declineMinorCodes)
minorErrorCodes.set(104, declineMinorCodes)
minorErrorCodes.set(109, declineMinorCodes)
minorErrorCodes.set(110, declineMinorCodes)
minorErrorCodes.set(111, declineMinorCodes)
minorErrorCodes.set(116, declineMinorCodes)
minorErrorCodes.set(119, declineMinorCodes)
minorErrorCodes.set(124, declineMinorCodes)
minorErrorCodes.set(125, declineMinorCodes)
minorErrorCodes.set(141, declineMinorCodes)
minorErrorCodes.set(208, declineMinorCodes)
minorErrorCodes.set(221, declineMinorCodes)
minorErrorCodes.set(222, declineMinorCodes)
minorErrorCodes.set(223, declineMinorCodes)
minorErrorCodes.set(224, declineMinorCodes)

// Recon

const recon = new Map()
recon.set(0, 'Undefined')
recon.set(1, 'Not Found')
recon.set(2, 'Mismatch [Status]')
recon.set(3, 'Mismatch [Currency]')
recon.set(4, 'Mismatch [Amount]')
recon.set(5, 'Full Match')

export const reconCodes = new Map()
reconCodes.set(0, recon)
reconCodes.set(1, recon)
reconCodes.set(2, recon)
reconCodes.set(3, recon)
reconCodes.set(4, recon)
reconCodes.set(5, recon)
