import AuthenticatedRoute from './AuthenticatedRoute'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { OrgScopeProvider } from './org-scope'
import { AuthProvider } from './auth'

// Our components
// Top-level user account stuff
import SignIn from './SignIn'
import SignOut from './SignOut'
import RequestAccount from './RequestAccount'
import PasswordResetInitiate from './PasswordResetInitiate'
import PasswordResetComplete from './PasswordResetComplete'
import NotFound from './NotFound'
import Support from './Support'

// Transactions
import ListTxns from './txns/ListTxns'
import ViewTxn from './txns/ViewTxn'
import EditTxnStatus from './txns/EditTxnStatus'

// Merchants
import ListMerchants from './merchants/ListMerchants'
import ViewMerchant from './merchants/ViewMerchant'

// Settings and related
import Settings from './settings/Settings'
import User from './user/User'

// Users
import ListUsers from './users/ListUsers'
import ViewUser from './users/ViewUser'
import CreateMerchant from './merchants/CreateMerchant'
import KYCProfile from './kyc/KYCProfile'

// Routing rules
import { RoutingRules } from './routing-rules/RoutingRules'
// Dashboard
import Dashboard from './dashboard/Dashboard'
import { ThemeProvider } from './theme'
import SignicatTheme from './signicat-theme/SignicatTheme'
import KycRouter from './kyc/KycRouter'

// Refunds
import RefundList from './refunds/RefundList'
import RefundTxn from './txns/RefundTxn'
import ManualRefundTxn from './txns/ManualRefundTxn'
import ManualChargebackTxn from './txns/ManualChargebackTxn'

/** App is the top-most component of the application. */
const App = () => (
  <ThemeProvider>
    <AuthProvider tokenKey='cosmo.tech'>
      <OrgScopeProvider>
        <Router>
          <Switch>
            <Route exact path='/'>
              <Redirect
                to={
                  window.matchMedia('(max-width: 420px)').matches
                    ? '/dashboard?from=m24h&to=now'
                    : '/transactions?from=m24h&to=now'
                }
              />
            </Route>
            <Route exact path='/sign-in' component={SignIn} />
            <Route exact path='/sign-out' component={SignOut} />
            <Route exact path='/request-account' component={RequestAccount} />
            <Route exact path='/password-reset' component={PasswordResetInitiate} />
            <Route exact path='/password-reset-complete' component={PasswordResetComplete} />
            <Route exact path='/support' component={Support} />
            <AuthenticatedRoute
              exact
              path='/know-your-customer/profile/new'
              component={KYCProfile}
            />
            <AuthenticatedRoute path='/know-your-customer' component={KycRouter} />
            <AuthenticatedRoute exact path='/account' component={User} />
            <AuthenticatedRoute exact path='/dashboard' component={Dashboard} />
            <AuthenticatedRoute exact path='/transactions' component={ListTxns} />
            <AuthenticatedRoute exact path='/transaction/:id' component={ViewTxn} />
            <AuthenticatedRoute exact path='/transaction/:id/status' component={EditTxnStatus} />
            <AuthenticatedRoute exact path='/transaction/:id/refund' component={RefundTxn} />
            <AuthenticatedRoute
              exact
              path='/transaction/:id/manualrefund'
              component={ManualRefundTxn}
            />
            <AuthenticatedRoute
              exact
              path='/transaction/:id/manualchargeback'
              component={ManualChargebackTxn}
            />
            <AuthenticatedRoute exact path='/users' component={ListUsers} />
            <AuthenticatedRoute exact path='/user/:id' component={ViewUser} />
            <AuthenticatedRoute exact path='/merchants' component={ListMerchants} />
            <AuthenticatedRoute exact path='/merchant/:id' component={ViewMerchant} />
            <AuthenticatedRoute exact path='/create/merchant' component={CreateMerchant} />
            <AuthenticatedRoute path='/settings' component={Settings} />
            <AuthenticatedRoute path='/routing-rules/:ruleset/:id' component={RoutingRules} />
            <AuthenticatedRoute path='/signicat-theme' component={SignicatTheme} />
            <AuthenticatedRoute path='/refunds' component={RefundList} />
            <Route path='*' component={NotFound} />
          </Switch>
        </Router>
      </OrgScopeProvider>
    </AuthProvider>
  </ThemeProvider>
)

export default App
