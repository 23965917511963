import { addFlexibleRules } from '../../api'
import { useAuth } from '../../auth'
import { GruleEditor } from '../grule-editor/RuleEditor'
import { useParams } from 'react-router-dom'
import { formatRules } from '../grule-editor/utils'
import { MerchantListHelper } from '../grule-editor/MerchantListHelper'
import { RouteListHelper } from '../grule-editor/RouteListHelper'

const CurrentRules = ({
  routingRules,
  updateRoutingRules,
  updateRoutingPlaintext,
  mode,
  loadNewlySavedRulesVersion,
  description,
  setDescription,
  errorHandler,
  setErrorHandler,
}) => {
  const { token } = useAuth()
  const { ruleset } = useParams()
  const includeSetRule = !ruleset.startsWith('scoring')

  const saveRules = async () => {
    if (!routingRules) {
      return
    }
    let rules
    if (mode === 'editor') {
      rules = formatRules(routingRules.rules, includeSetRule) // convert grule parsed rules into a string
    } else {
      rules = routingRules.plaintext
    }
    console.log(rules)

    try {
      await addFlexibleRules(token, ruleset, description, rules)
      loadNewlySavedRulesVersion()
      setErrorHandler()
    } catch (e) {
      setErrorHandler(e?.details)
    }
  }

  return (
    <>
      {routingRules.loaded && (routingRules.type == 'json' || mode == 'plaintext') ? (
        <>
          <div className='ruleset-action-container'>
            <input
              type='text'
              placeholder='Ruleset Description'
              size='60'
              value={description}
              onChange={(event) => setDescription(event?.target?.value)}
            />
            <button
              className='action-button'
              onClick={saveRules}
              disabled={!description || !routingRules}
            >
              Save
            </button>
            <p className={`${errorHandler ? null : 'error-message-hidden'} error-message`}>
              {errorHandler}
            </p>
          </div>
          <textarea
            onChange={(e) => updateRoutingPlaintext(e.target.value)}
            className='routing-text-area'
            value={routingRules.plaintext}
            placeholder={`Input new rules`}
          ></textarea>
        </>
      ) : null}
      {routingRules.loaded && routingRules.type == 'grule' && mode == 'editor' ? (
        <>
          <div className='ruleset-action-container'>
            <input
              type='text'
              placeholder='Ruleset Description'
              size='60'
              value={description}
              onChange={(event) => setDescription(event?.target?.value)}
            />
            <button
              className='action-button'
              onClick={saveRules}
              disabled={!description || !routingRules}
            >
              Save
            </button>
            <p className={`${errorHandler ? null : 'error-message-hidden'} error-message`}>
              {errorHandler}
            </p>
          </div>
          <GruleEditor rules={routingRules.rules} updateRules={updateRoutingRules}></GruleEditor>
        </>
      ) : null}
      {mode == 'merchant' ? <MerchantListHelper /> : null}
      {mode == 'routes' ? <RouteListHelper /> : null}
    </>
  )
}
export default CurrentRules
