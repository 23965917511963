import Header from '.././Header'
import Helmet from 'react-helmet'
import { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useAuth } from '../auth'
import DateTime from '../DateTime'
import AmountWithCurrency from '../AmountWithCurrency'
import DownloadCSVLink from './DownloadCSVLink'
import { isOperator } from '../auth-roles'

import { getRefundedTransactions, getRefundedTransactionsFiltered } from '../api'

const RefundList = () => {
  const history = useHistory()

  const defaultRecordsPerPage = 50 //Default for now. As functionality gets used we can push this up.
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const [entriesList, setEntriesList] = useState([])
  const [recordsPerPage] = useState(defaultRecordsPerPage)
  const [pageCount, setPageCount] = useState(recordsPerPage)
  const [loading, setLoading] = useState(false)
  const [totalRecords, setTotalrecords] = useState(recordsPerPage)
  const [statusFilter, setStatusFilter] = useState('')
  const [transactionIDFilter, setTransactionIDFilter] = useState('')
  const [refundIDFilter, setRefundIDFilter] = useState('')
  const [pspFilter, setPSPFilter] = useState('')
  const [refundFilterQuery, setRefundFilterQuery] = useState('')

  const fetchRefunds = async () => {
    setEntriesList([])
    setLoading(true)

    try {
      const queryResults = await getRefundedTransactions(token, pageCount)
      setTotalrecords(queryResults.page.total_count)
      setEntriesList(queryResults.results)
      setLoading(false)
    } catch (e) {
      setTotalrecords(0)
      setLoading(false)
    }
  }

  const filterTable = async (column, value, newFilter) => {
    let filterQuery
    if (value.length <= 0) {
      reset()
    } else {
      if (column == 'refund_id') {
        let refundID = value.toLowerCase()
        setRefundIDFilter(refundID)
        filterQuery = `refund_id_cnts=${refundID}`
      }

      if (column == 'transaction_id') {
        let transactionID = value.toLowerCase()
        setTransactionIDFilter(transactionID)
        filterQuery = `txn_id_cnts=${transactionID}`
      }

      if (column == 'psp_id') {
        let pspID = value.toLowerCase()
        setPSPFilter(pspID)
        filterQuery = `psp_id_cnts=${pspID}`
      }

      if (column == 'status') {
        let status = value.toLowerCase()
        setStatusFilter(status)
        filterQuery = `status=${status}`
      }

      var amountOfRecordsToLoad = pageCount + loadableCount
      // If this is a new filter query we start afresh!
      if (newFilter) {
        amountOfRecordsToLoad = recordsPerPage
        setPageCount(recordsPerPage)
      } else {
        setPageCount(amountOfRecordsToLoad)
      }

      setLoading(true)

      if (filterQuery.length > 0) {
        setRefundFilterQuery(filterQuery)
      }

      const queryResults = await getRefundedTransactionsFiltered(
        token,
        filterQuery,
        amountOfRecordsToLoad
      )
      setTotalrecords(queryResults.page.total_count)
      setEntriesList(queryResults.results)
      setLoading(false)
    }
  }

  const reset = async () => {
    setRefundIDFilter('')
    setPSPFilter('')
    setTransactionIDFilter('')
    setStatusFilter('')

    setPageCount(recordsPerPage)

    setLoading(true)
    const queryResults = await getRefundedTransactions(token, recordsPerPage)
    setTotalrecords(queryResults.page.total_count)
    setEntriesList(queryResults.results)
    setLoading(false)
  }

  const showMore = async () => {
    var isFiltering = false
    var amountOfRecordsToLoad = pageCount + loadableCount

    // Are we busy with a filter?
    setPageCount(amountOfRecordsToLoad)
    if (refundIDFilter != '') {
      isFiltering = true
      filterTable('refund_id', refundIDFilter, false)
    }
    if (pspFilter != '') {
      isFiltering = true
      filterTable('psp_id', pspFilter, false)
    }
    if (statusFilter != '') {
      isFiltering = true
      filterTable('status', statusFilter, false)
    }
    if (transactionIDFilter != '') {
      isFiltering = true
      filterTable('transaction_id', transactionIDFilter, false)
    }

    if (!isFiltering) {
      setLoading(true)
      setPageCount(amountOfRecordsToLoad)

      const queryResults = await getRefundedTransactions(token, amountOfRecordsToLoad)
      setTotalrecords(queryResults.page.total_count)
      setEntriesList(queryResults.results)

      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRefunds()
  }, [])

  const loadableCount = Math.min(
    recordsPerPage,
    entriesList & entriesList?.length ? totalRecords - entriesList?.length : recordsPerPage
  )

  return (
    <section>
      <Header />
      <Helmet>
        <title>Refunds</title>
      </Helmet>
      <div className='content'>
        <header className='controls'>
          <span className='summary'>
            {entriesList && (
              <span className='summary'>
                Showing <strong className='count page_count'>{entriesList?.length}</strong>{' '}
                {entriesList && entriesList?.length !== totalRecords && (
                  <span>
                    of
                    <strong className='count total_count'>{totalRecords.toLocaleString()}</strong>
                  </span>
                )}
                {entriesList?.loading && <span className='loading'>updating...</span>}
              </span>
            )}
          </span>
        </header>
        <table className='refund-filter-table'>
          <tbody>
            <tr>
              <td>
                <button className='remove' title='Clear Filters' onClick={reset} disabled={false}>
                  {' '}
                  <i className='fas fa-undo-alt' />
                  {' Clear Filters'}
                </button>
              </td>
              <td></td>
              <td className='refund-filter-more-container'>
                {entriesList && entriesList?.length !== totalRecords && (
                  <>
                    <button onClick={() => showMore()} disabled={loading}>
                      {loading ? 'Loading...' : `Show ${loadableCount} more`}
                    </button>
                    &nbsp;&nbsp;
                  </>
                )}
                <DownloadCSVLink displayCount={totalRecords} filter={refundFilterQuery} />
              </td>
            </tr>
          </tbody>
        </table>
        <table className='txns'>
          <thead>
            <tr>
              <th className='refund-txn-spacer'></th>
            </tr>
            <tr className='table-filter-row'>
              <th>#</th>
              <th>
                <h6>Refund ID</h6>
                <input
                  type='text'
                  value={refundIDFilter}
                  onChange={(e) => filterTable('refund_id', e.target.value, true)}
                />
              </th>
              <th>
                <h6>Transaction ID</h6>
                <input
                  type='text'
                  value={transactionIDFilter}
                  onChange={(e) => filterTable('transaction_id', e.target.value, true)}
                />
              </th>
              <th>
                <h6>PSP Transaction ID</h6>
              </th>
              <th>
                <h6>PSP Refund ID</h6>
              </th>
              <th>
                <h6>PSP</h6>
                <input
                  type='text'
                  value={pspFilter}
                  onChange={(e) => filterTable('psp_id', e.target.value, true)}
                  style={{ width: '120px' }}
                />
              </th>
              <th>
                <h6>PSP MID</h6>
              </th>
              <th>
                <h6>Status</h6>
                <input
                  type='text'
                  value={statusFilter}
                  onChange={(e) => filterTable('status', e.target.value, true)}
                  style={{ width: '75px' }}
                />
              </th>
              <th>
                <h6>Amount</h6>
              </th>
              <th>
                <h6>By</h6>
              </th>
              <th>
                <h6>Created At</h6>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td height='10'></td>
            </tr>
            {entriesList &&
              entriesList.map((refund, index) => {
                return (
                  <tr className='txn clickable' key={index}>
                    <td onClick={() => history.push(`/transaction/${refund.transaction_id}`)}>
                      {index + 1}
                    </td>
                    <td
                      className='refund-id'
                      onClick={() => history.push(`/transaction/${refund.transaction_id}`)}
                    >
                      {refund.refund_id || '-'}
                    </td>
                    <td
                      className='refund-txn-id'
                      onClick={() => history.push(`/transaction/${refund.transaction_id}`)}
                    >
                      {' '}
                      {refund.transaction_id || '-'}
                    </td>
                    <td>{refund.psp_transaction_id || '-'}</td>
                    <td>{refund.psp_refund_id || '-'}</td>
                    <td>{refund.psp_id || '-'}</td>
                    <td>{refund.psp_credential_id || '-'}</td>
                    {refund.status == 'failed' && (
                      <td className='refund-txn-failed'>
                        {refund.type == 'chargeback' && (
                          <i
                            className='fas fa-comment-dollar refund-txn-chargeback'
                            title={refund.type}
                          ></i>
                        )}
                        {refund.type == 'refund' && (
                          <i
                            className='fas fas fa-credit-card refund-txn-refund'
                            title={refund.type}
                          ></i>
                        )}{' '}
                        {refund.status}
                      </td>
                    )}
                    {refund.status == 'success' && (
                      <td className='refund-txn-success'>
                        {refund.type == 'chargeback' && (
                          <i
                            className='fas fa-comment-dollar refund-txn-chargeback'
                            title={refund.type}
                          ></i>
                        )}
                        {refund.type == 'refund' && (
                          <i
                            className='fas fas fa-credit-card refund-txn-refund'
                            title={refund.type}
                          ></i>
                        )}{' '}
                        {refund.status}
                      </td>
                    )}
                    {refund.status == 'pending' && (
                      <td className='refund-txn-pending'>{refund.status}</td>
                    )}
                    {refund.status == 'open' && (
                      <td className='refund-txn-open'>{refund.status}</td>
                    )}
                    <td>
                      <AmountWithCurrency
                        amount={refund.amount.amount}
                        currency={refund.amount.currency}
                      />
                    </td>
                    <td>
                      {isUserOperator && refund.requested_by != '' && (
                        <Link to={`/user/${refund.requested_by}`}>
                          <i
                            className='fas fa-user-alt'
                            title={refund.requested_by_displayname}
                          ></i>
                        </Link>
                      )}
                      {!isUserOperator && refund.requested_by != '' && (
                        <i className='fas fa-user-alt' title={refund.requested_by_displayname}></i>
                      )}
                      {refund.requested_by == '' && (
                        <i className='fas fa-user-alt' title='operator'></i>
                      )}
                    </td>
                    <td>
                      <DateTime at={refund.created_at} />
                    </td>
                    <td>
                      <Link to={`/transaction/${refund.transaction_id}`}>Detail</Link>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        <br />
        <br />
      </div>
    </section>
  )
}

export default RefundList
